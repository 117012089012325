import { ConfigBaseItem } from '../shared/ConfigBaseItem';
import { LinkUrlIcon } from '../shared/Icons/InfoIcons';
import { ConfigInput } from '../shared/Input/ConfigInput.style';

type Props = {
  item: any;
};

export default function Link({ item }: Props) {
  const linkStyle = {
    color: 'blue',
    textDecoration: 'underline',
    cursor:
      item.FIELDVALUE !== '' && item.FIELDVALUE !== undefined && item.FIELDVALUE !== null
        ? 'pointer'
        : 'default',
  };

  const handleClick = (e: any) => {
    if (!item.FIELDVALUE) {
      e.preventDefault(); // Prevent the default click behavior if FIELDVALUE is empty
    }
  };

  return (
    <ConfigBaseItem item={item} leftIcon={<LinkUrlIcon />}>
      <a
        href={item.FIELDVALUE}
        target='_blank'
        rel='noopener noreferrer'
        onClick={handleClick}
      >
        <ConfigInput defaultValue={item.FIELDVALUE} style={linkStyle} readOnly />
      </a>
    </ConfigBaseItem>
  );
}
