import { useMemo, useState } from 'react';

import isDate from 'date-fns/isDate';
import { enGB } from 'date-fns/locale';
import { useDateInput } from 'react-nice-dates';

import { FormContainer } from 'components/Search/Search.style';
import { TimeIcon, LockedFloatIcon } from 'components/shared/Icons/InfoIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';

import TimePicker from './TimePicker';

type Props = {
  item: any;
  position?: string;
  isDisabled?: boolean;
  handleChange: (date: Date) => void;
  baseStatus: any;
};

export function DateTimeInput({
  item,
  position,
  isDisabled,
  handleChange,
  baseStatus,
}: Props) {
  const DEFAULT_DATE = '';
  const [isOpen, setIsOpen] = useState(false);

  const stringDate = useMemo(() => {
    // safari dont support '-' format
    const date = item.FIELDVALUE || DEFAULT_DATE || '';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isDate(date)) return date.toString();
    return date.replace(/-/g, '/');
  }, [item.FIELDVALUE]);

  const inputProps = useDateInput({
    date: new Date(stringDate),
    format: 'HH:mm',
    locale: enGB,
    onDateChange: handleChange,
  });

  const submitPicker = (date: any) => {
    setIsOpen(false);
    handleChange(date);
  };

  return (
    <FormContainer>
      {item.FIELDREADONLY ? <LockedFloatIcon /> : <TimeIcon />}
      <ConfigInput
        {...inputProps}
        className='input'
        onChange={(e) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const arrVal = [...e.target.value];
          if (arrVal.length === 3 && !arrVal.includes(':')) {
            arrVal.splice(2, 0, ':');
            e.target.value = arrVal.join('');
          }
          inputProps.onChange(e);
        }}
        onClick={() => setIsOpen(true)}
        disabled={isDisabled}
        modifiers={baseStatus.status}
        inputMode='none'
      />
      {isOpen && (
        <TimePicker
          date={stringDate}
          position={position}
          onClose={() => setIsOpen(false)}
          handleChange={submitPicker}
        />
      )}
    </FormContainer>
  );
}
