import { BiTime } from 'react-icons/bi';
import styled from 'styled-components';

import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as Exam } from 'assets/icons/exam.svg';
import { ReactComponent as Information } from 'assets/icons/information-outline.svg';
import { ReactComponent as Check } from 'assets/icons/interface.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ReactComponent as LockedClosedIcon } from 'assets/icons/lock-closed.svg';
import { ReactComponent as SearchBoxIcon } from 'assets/icons/search.svg';
import { ReactComponent as TextBoxIcon } from 'assets/icons/text-box.svg';
import { inputIconStyle } from 'components/Search/Search.style';

export const InfoIcon = styled(Information)`
  cursor: pointer;
  height: 1.8rem;
  width: 2rem;
  font-weight: 600;
  fill: ${(props) => props.theme.error};
`;
export const LockedFloatIcon = styled(LockedClosedIcon)`
  ${inputIconStyle}
`;
export const TextIcon = styled(TextBoxIcon)`
  ${inputIconStyle}
`;
export const SearchIcon = styled(SearchBoxIcon)`
  ${inputIconStyle}
`;
export const LinkUrlIcon = styled(LinkIcon)`
  ${inputIconStyle}
`;
export const CalendarIcon = styled(Calendar)`
  ${inputIconStyle}
`;
export const TimeIcon = styled(BiTime)`
  ${inputIconStyle}
`;

export const CheckIcon = styled(Check)`
  height: 2rem;
  width: 2rem;
  font-weight: 700;
  fill: ${(props) => props.theme.greenLight};
`;

export const ExamIcon = styled(Exam)`
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props) => props.theme.gray};
  left: 0;
  height: 2rem;
  width: 2rem;
`;
