import { useState, useMemo } from 'react';

import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { StyledArrowDown, StyledCloseIcon } from 'components/shared/Icons/ActionIcons';
import { SearchIcon } from 'components/shared/Icons/InfoIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { ScaleLoader } from 'components/shared/Loading';
import Modal from 'components/shared/Modal/Modal';
import TablePicker from 'components/Table/TablePicker/TablePicker';

import { filterData } from './List.utils';

type Props = {
  item: any;
  send: any;
  data: any;
  error: any;
  isLoading: any;
  displayValue: any;
};

function ListWrapper({ item, send, data, error, isLoading, displayValue }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tableInfo = useMemo(() => {
    return filterData(data, item.FIELDDISPLAY, item.FIELDDISPLAYCAPTION);
  }, [item.FIELDDISPLAY, data, item.FIELDDISPLAYCAPTION]);

  function handleTableClick(row: any) {
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      FIELDVALUE: row.ID[0],
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
    setIsModalOpen(false);
  }

  function openModal() {
    if (error || !data) return;
    setIsModalOpen(true);
  }
  function clearSelection(e: any) {
    e.stopPropagation();
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      FIELDVALUE: null,
      FIELDFORCECALC: true,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
  }

  return (
    <>
      <Modal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <TablePicker
          type='List'
          tableOffset={5}
          valueData={tableInfo?.data}
          keyData={tableInfo?.columns}
          handleClickRow={handleTableClick}
        />
      </Modal>
      <ConfigBaseItem
        item={item}
        leftIcon={<SearchIcon />}
        rightIcon={(() => {
          if (isLoading) {
            return <ScaleLoader height={10} width={3} />;
          }
          if (error) {
            return <ErrorInfo id={item.FIELDID} message={error?.message} />;
          }
          if (displayValue) {
            return <StyledCloseIcon onClick={clearSelection} />;
          }
          return <StyledArrowDown onClick={openModal} />;
        })()}
      >
        {({ baseStatus }) => (
          <ConfigInput
            data-cy={`input-${item.FIELDID}`}
            readOnly
            onClick={openModal}
            onChange={() => {}}
            value={displayValue || ''}
            // $noIcon={!item.FIELDREADONLY}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modifiers={baseStatus.status}
          />
        )}
      </ConfigBaseItem>
    </>
  );
}

export default ListWrapper;
