import { LuFileSearch2 } from 'react-icons/lu';
import styled from 'styled-components';

import { HomeLink } from 'components/PageNotFound/PageNotFound.style';
import { device } from 'config/device';

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 16px 32px;
  border-radius: 12px;
  margin-top: 20px;
  min-height: 400px;
  justify-content: center;

  h4 {
    padding: 8px 12px;
    font-size: 16px;
    font-weight: normal;
  }
  p {
    text-align: center;
    color: ${(props) => props.theme.gray3};
    font-weight: normal;
    line-height: 20px;
    max-width: 400px;
  }

  svg {
    width: 56px;
    height: 56px;
    color: ${(props) => props.theme.special};
    margin-bottom: 20px;
  }

  @media ${device.mobile} {
    padding: 8px 12px;
  }
`;

export default function EmptyPage() {
  return (
    <StyledDiv>
      <LuFileSearch2 />
      <h4>No Content</h4>
      <p>
        This page does not contain any data or content yet. You can add new items, import
        data, or navigate to other pages.
      </p>

      <HomeLink to='/'> Home</HomeLink>
    </StyledDiv>
  );
}
