import { memo } from 'react';

import { motion } from 'framer-motion';

import ToggleButton from 'components/Charts/ToggleButton';
import { GroupContainer } from 'components/Groups/Group.style';
import { ClipLoader } from 'components/shared/Loading';
import { StyledFlex } from 'components/shared/shared.style.';

import { ChartContainer, ChartHeader, ChartButton } from './Charts.style';

type Props = {
  type?: 'list' | 'chart';
  showList?: boolean;
  nextChart?: any;
  onChangeToList?: () => void;
  onChartChange?: () => void;
  queryData: any;
  description: string;
  children: React.ReactNode;
};

export function ChartBody({
  type,
  showList,
  nextChart,
  onChangeToList,
  onChartChange,
  queryData,
  description,
  children,
}: Props) {
  const { status, error, isFetching } = queryData;
  return (
    <div className='chart-item'>
      <GroupContainer
        className='chart-group-container'
        style={{ height: '100%', gridGap: '2rem', padding: '2rem 0 0 0' }}
      >
        <ChartHeader>
          <h3>{description}</h3>
          {(() => {
            if (type === 'list' || status !== 'success') {
              return null;
            }
            return (
              <StyledFlex $gridGap={'8px'}>
                {/* <ChartButton type='secondary' onClick={onChangeToList}>
                  {showList ? 'Chart' : 'List'}
                </ChartButton> */}
                {!showList && (
                  <ChartButton onClick={onChartChange}>{nextChart?.label}</ChartButton>
                )}
                <ToggleButton onChangeToList={onChangeToList || (() => {})} />
              </StyledFlex>
            );
          })()}
        </ChartHeader>
        <ChartContainer $isList={type === 'list'}>
          {(() => {
            if (status === 'error') {
              return (
                <div className='info-container error'>
                  <h4>{error.message}</h4>
                </div>
              );
            }
            if (status === 'loading' || isFetching) {
              return (
                <div className='info-container loading'>
                  <ClipLoader size={70} />
                </div>
              );
            }
            return (
              <motion.div
                className='children'
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                {children}
              </motion.div>
            );
          })()}
        </ChartContainer>
      </GroupContainer>
    </div>
  );
}

export default memo(ChartBody);
