import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { device } from 'config/device'; // Assuming you have a media query config file

export const PageNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  height: 100vh;
  font-family: 'Arial', sans-serif;
`;

export const NotFoundNumber = styled.div`
  font-size: 12rem;
  font-weight: bold;
  color: ${({ theme }) => theme.error}; // You can replace this with any desired color
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

  @media ${device.tablet} {
    font-size: 8rem;
  }
`;

export const ErrorText = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.secondary};
  margin: 1rem 0 2rem 0;

  @media ${device.tablet} {
    font-size: 1.5rem;
  }
`;

export const HomeLink = styled(Link)`
  padding: 1.5rem 3rem;
  background-color: ${({ theme }) => theme.special};
  color: #fff;
  font-weight: bold;
  border-radius: 12px;
  text-decoration: none;
  transition: opacity 0.3s;
  :hover {
    opacity: 0.9;
  }
  margin-top: 2rem;
`;
