import { useRef, useEffect } from 'react';

import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';

import { ConfigTextarea } from './Textarea.style';

type TextareaProps = {
  item: any;
  send: any;
};

export default function Textarea({ item, send }: TextareaProps) {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = item.FIELDVALUE || '';
    }
  }, [item.FIELDVALUE]);

  function handleBlur() {
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      DISPLAYVALUE: inputRef?.current.value,
      FIELDVALUE: inputRef?.current.value,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
  }

  return (
    <ConfigBaseItem item={item}>
      {({ baseStatus }) => (
        <ConfigTextarea
          disabled={Boolean(item.FIELDREADONLY)}
          ref={inputRef}
          onBlur={handleBlur}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          modifiers={baseStatus.status}
        />
      )}
    </ConfigBaseItem>
  );
}
