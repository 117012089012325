import React, { useState } from 'react';

import { FaChartBar, FaList } from 'react-icons/fa';
import styled from 'styled-components';

// Props for the ToggleButton component
interface ToggleButtonProps {
  onChangeToList: () => void;
}

// Props for styled components
interface OptionProps {
  isSelected: boolean;
}

// Styled components
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #f1f1f1;
  cursor: pointer;
  position: relative;
  padding: 5px;
`;

const Option = styled.div<OptionProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => (props.isSelected ? 'white' : '#555')};
  border-radius: 15px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.special : 'transparent'};
  transition: all 0.3s ease;
  height: 100%;
`;

const ToggleButton: React.FC<ToggleButtonProps> = ({ onChangeToList }) => {
  const [selectedOption, setSelectedOption] = useState<'left' | 'right'>('left');

  const handleOptionChange = (option: 'left' | 'right') => {
    setSelectedOption(option);
    onChangeToList();
  };

  return (
    <ToggleContainer>
      <Option
        isSelected={selectedOption === 'left'}
        onClick={() => handleOptionChange('left')}
      >
        <FaList />
      </Option>
      <Option
        isSelected={selectedOption === 'right'}
        onClick={() => handleOptionChange('right')}
      >
        <FaChartBar />
      </Option>
    </ToggleContainer>
  );
};

export default ToggleButton;
